import { OrgStruct, UserAnalytics, UserStruct } from './firestore-structs'
import { Firebase, FirebaseFirestore } from './firebase'

export async function setUser(
    firebase: Firebase,
    user: UserStruct & {
        type?: string
        authRole?: string
    },
    organization: OrgStruct
) {
    if (!user.key) {
        console.log('NO ANALYTICS USER ID')
        return
    }
    if (firebase.analytics) {
        await firebase.analytics().setUserId(user.key)
    }

    let userType = 'customer'
    // eslint-disable-next-line no-undef
    if (process.env.NODE_ENV === 'development' || user.phoneNumber.substring(0, 6) === '+354999') {
        userType = 'developer'
    } else if (user.type !== undefined) {
        userType = user.type
    }
    if (firebase.analytics) {
        await firebase.analytics().setUserProperties({ userType })
    }

    if (user.organizationKey) {
        if (firebase.analytics) {
            await firebase.analytics().setUserProperties({ organizationKey: user.organizationKey })
        }
    }
    if (user.authRole) {
        if (firebase.analytics) {
            await firebase.analytics().setUserProperties({ authRole: user.authRole })
        }
    }
    if (organization.subscription) {
        if (firebase.analytics) {
            await firebase.analytics().setUserProperties({ subscription: organization.subscription })
        }
    }
    if (organization.pms) {
        if (firebase.analytics) {
            await firebase.analytics().setUserProperties({ pms: organization.pms })
        }
    }
    if (organization.brand) {
        if (firebase.analytics) {
            await firebase.analytics().setUserProperties({ brand: organization.brand })
        }
    }
}

export async function logEvent(firebase: Firebase, event: string, parameter: string | null, value?: string | number | boolean | null) {
    let objParameter = {}
    if (value !== undefined && value !== null) {
        objParameter = {
            [parameter!]: String(value)
        }
    }
    if (firebase.analytics) {
        await firebase.analytics().logEvent(event, objParameter)
    }
}

export class Analytics {
    firestore: FirebaseFirestore

    constructor(firebase: FirebaseFirestore) {
        this.firestore = firebase
    }

    async updateWebUserFingerprint(
        info: {
            userKey: string
            orgKey: string
        } & Omit<UserAnalytics['web'], 'updated'>
    ) {
        await this.firestore
            .collection<UserAnalytics>('user-analytics')
            .doc(info.userKey)
            .set(
                {
                    organizationKey: info.orgKey,
                    web: {
                        sha: info.sha,
                        agent: info.agent,
                        browser: info.browser,
                        version: info.version,
                        locale: info.locale,
                        updated: Date.now()
                    }
                },
                { merge: true }
            )
    }
    async updateMobileUserFingerprint(
        info: {
            userKey: string
            orgKey: string
        } & Omit<UserAnalytics['mobile'], 'updated'>
    ) {
        await this.firestore
            .collection<UserAnalytics>('user-analytics')
            .doc(info.userKey)
            .set(
                {
                    organizationKey: info.orgKey,
                    mobile: {
                        applicationVersion: info.applicationVersion,
                        buildVersion: info.buildVersion,
                        deviceBrand: info.deviceBrand,
                        deviceID: info.deviceID,
                        runtimeVersion: info.runtimeVersion,
                        sha: info.sha,
                        platform: info.platform,
                        update: info.update,
                        locale: info.locale,
                        updated: Date.now()
                    }
                },
                { merge: true }
            )
    }
}
